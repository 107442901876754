.breadcrumb {
    margin-bottom: 20px;
    list-style: none;

    > li {
        display: inline-block;
        font-size: 13px;

        &:not(:first-child) {
            &:before {
                content: '>';
                color: #818da7;
                margin: 0 5px;
            }
        }

        &.active {
            // color: #464f63;
            color: #DBE2EA;
            font-weight: 500;
        }

        > a, > a:visited {
            color: #545b68;

            &:hover, &:focus {
                color: #3d424c;
            }
        }

        i {
            display: inline-block;
            vertical-align: baseline;
            margin-right: .375em;
            position: relative;
            top:-2px;
        }
    }
}