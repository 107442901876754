main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
  }
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 2px;
  margin-bottom: 2px;
}

.ant-layout-sider {
  .logo {
    //border-bottom: 1px solid #dbe2ea;
    text-align: center;
    padding: 15px;
    padding-top: 50px;
    padding-bottom: 30px;

    img {
      max-height: 100% !important;
      max-width: 100% !important;
      width: 100%;
    }

    Button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 34px !important;
    padding-right: 34px;
    text-align: left;

    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 400;
      color: black;
    }

    .ant-menu-title-content:hover {
      // color: darken($wht, 30%);
      color: black;
    }

    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .ant-menu-sub.ant-menu-inline>.ant-menu-item {
    height: 35px;
  }

  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-item-only-child {
  margin-bottom: 0;
  margin-top: 0;
}

.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

span.anticon {
  vertical-align: 0.15em;
}

// main.ant-layout-content {
//   padding: 0 0 0 15px !important;
// }

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    .logo {
      border: none;
      padding: 20px 0;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-menu-dark .ant-menu-item:hover>span>a {
    color: black;
  }

  .ant-menu-dark .ant-menu-item-selected>a,
  .ant-menu-dark .ant-menu-item-selected>span>a,
  .ant-menu-dark .ant-menu-item-selected>a:hover,
  .ant-menu-dark .ant-menu-item-selected>span>a:hover {
    color: black;
  }

  .ant-layout.ant-layout-has-sider>.ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}

.ant-menu.ant-menu-dark {
  background: white;
}

// .ant-menu-submenu-open {
//   .ant-menu-submenu-title {
//     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 3%, rgba(0,0,0,0) 100%);
//     margin: 0;
//   }
//   .ant-menu-submenu-title:hover {
//     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 3%, rgba(0,0,0,0) 100%);
//   }
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  // background-color: rgba(0, 129, 57, 0.1);
  background-color: rgba(108, 156, 180, 0.3);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
  content: '';
  width: 5px;
  height: 25px;
  background: transparent;
  border-radius: 0 6px 6px 0;
  position: absolute;
  left: 0;
  top: 5px;
}

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu {
  font-weight: 400;
}

.sider-profile-controls {
  box-sizing: border-box;
  width: 100%;
  // position: absolute;
  // bottom: 40px;
  //height: 40px;
  line-height: 40px;
  padding: 0 34px;
  margin: 20px 0;

  .sider-profile-controls-profile,
  .sider-profile-controls-logout {
    font-size: 22px;
  }

  .sider-profile-controls-profile:hover,
  .sider-profile-controls-logout:hover,
  .sider-profile-controls-language:hover {
    color: darken($wht, 30%);
  }

  .sider-profile-controls-profile {
    margin-right: 20px;
  }


  .sider-profile-controls-language {
    padding: 0;
    margin: 0;
    color: $k100;

  }
}

.nav-display {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu {
      flex-grow: 2;


    }
  }
}

ukidanje scroll-ova * {
  scrollbar-width: none;
  /* Firefox i Chrome ukidanje svih scrollbar-a*/
}

.ant-layout-sider-children {
  scrollbar-width: none;
  /* Firefox i Chrome ukidanje svih scrollbar-a*/
}

* {
  -ms-overflow-style: none !important;
}

::-webkit-scrollbar {
  display: none;
}