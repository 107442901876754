button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  >* {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right>* {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.log-out-btn {
  @include btn(transparent, #337aed);
  width: 100%;
}

.ant-btn {
  line-height: normal;
  color: #fff;
  // background: linear-gradient(180deg, #3372ed, #1138c2);
  // background: linear-gradient(180deg, #d9cdc4, #bda18c);
  background: #FB8937;
  border: #FB8937;
  border-radius: 5px;

  &:hover {
    // opacity: 0.5;
    background: #FB8937;
  }

  &:active,
  &:focus {
    // background: linear-gradient(180deg, #2768e9, #0b32c0f6);
    background: #d9cdc4;
    color: #fff;
  }
}

.ant-btn {
  line-height: normal;
}

.ant-input:hover {
  border-color: #FB8937;
}

.ant-input:focus {
  border: 1px solid #FB8937;
  // box-shadow: 2px 2px 2px rgba(121, 153, 188, 0.3);
  box-shadow: 2px 2px 2px rgba(156, 178, 66, 0.3);
}

.b2b-primary {
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  // user-select: none;
  padding: 4px 15px;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  transition: none;
  color: #fff;
  background: #FB8937;
  border: 2px solid #FB8937;
  border-radius: 5px;
  line-height: normal;
  cursor: pointer;
}

.b2b-primary:hover {
  opacity: 0.8;
}

.b2b-primary:active {
  opacity: 0.8;
}

.b2b-secondary {
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  // user-select: none;
  padding: 4px 15px;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  transition: none;
  background: #FB8937;
  color: white;
  border: 2px solid #FB8937;
  border-radius: 5px;
  line-height: normal;
  cursor: pointer;
}

.b2b-secondary:hover {
  opacity: 0.8;
  background: #FB8937;
  color: white;
}

.b2b-secondary:active {
  opacity: 0.8;
}

.ant-upload-list-item-card-actions {
  button {
    background: transparent !important;
    width: 30px !important;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #008139;
  // box-shadow: 0 0 0 3px rgba(156, 178, 66, 0.2);
  box-shadow: 0px 4px 2px -2px rgba(156, 178, 66, 0.3);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #008139;
  // box-shadow: 0 0 0 3px rgba(156, 178, 66, 0.2);
  box-shadow: 0px 4px 2px -2px rgba(156, 178, 66, 0.3);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #008139;
  // box-shadow: 0 0 0 3px rgba(156, 178, 66, 0.2);
  box-shadow: 0px 4px 2px -2px rgba(156, 178, 66, 0.3);
}

// .ant-input:focus {
//   border: 1px solid #16648D;
//   box-shadow: 2px 2px 2px rgb(121 153 188 / 30%); // ovo je shadow za input / select polja
// }

// .ant-input:hover {
//   border-color: darkgreen;
// }

.pdf_container {

  & .pdf_button {
    background-color: #FB8937;
    color: white;
    border-radius: 5px;
    height: 38px;
    width: 100%;
    padding: 4px 15px;
    font-weight: 700;
    font-size: 13px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

    &:hover {
      opacity: .8;
    }
  }
}