.jodit-tooltip {
    padding: 0 !important;
}

.jodit_sticky > .jodit-toolbar__box {
    top:-15px !important;
}

.jodit-dialog__box{
    @include media("<=phone2") {
        z-index: 1000000 !important;
    }
}

.jodit-dialog{

    border-radius: 4px;

    @include media(">=laptop") {
        left: 40% !important;
        top: 40% !important;
    }

    @include media(">phone2","<=tablet") {
        left: 22% !important;
    }

    font-family: 'Lato', sans-serif;

    .jodit-dialog__header{
        cursor: default !important;
        user-select: none !important;
        font-size: 19px !important;

        .jodit-dialog__header-title{
            padding-left: 15px;
        }

        .jodit-dialog__header-toolbar{
            padding-right: 8px;
        }

        @include media("<=phone2"){
            flex-direction: row !important;
        }
    }

    .jodit-dialog_prompt{
        color: rgba(0, 0, 0, .8) !important;
        max-width: none !important;
        font-size: 16px !important;
        padding-top: 20px !important;
        padding-left: 15px !important;
    }

    .jodit-dialog__content{
        min-height: 58px !important;
    }

    .jodit-dialog__footer{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .jodit-ui-button{
        // background-color: #259dab;
        // border-color: #259dab;
        background-color: #4F66A0;
        border-color: #4F66A0;
        text-transform: uppercase;
        height: 33px;
        letter-spacing: .05em;
        font-size: 12px;
        border-radius: 2px;
        color:#fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, .045);

        &:hover,
        &:active{
            background-color: #28a9b8 !important;
            border-color: #28a9b8 !important;
        }

        .jodit-ui-button__text{
            font-size: 12px;
        }
    }


    .jodit-dialog__resizer{
        cursor: default !important;
        width:0!important;
        height: 0!important;
    }
}