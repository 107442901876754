th,
td {
  font-size: 0.875em;
}

th {
  text-align: left;
}

.table>div>h2 {
  margin-top: 0.5em !important
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    padding: 13px 15px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ffffff;
  }

  >thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
  }

  >caption+thead>tr:first-child>th,
  >colgroup+thead>tr:first-child>th,
  >thead:first-child>tr:first-child>th,
  >caption+thead>tr:first-child>td,
  >colgroup+thead>tr:first-child>td,
  >thead:first-child>tr:first-child>td {
    border-top: 0;
  }

  >tbody {
    +tbody {
      border-top: 2px solid #ffffff;
    }

    tr {
      &:hover {
        >td {
          background-color: #f0f6f8;
        }
      }
    }
  }
}

.table>thead>tr:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.table>thead>tr:first-child>th:first-child {
  border-top-left-radius: 2px;
}

.table>thead>tr:first-child>th:last-child {
  border-top-right-radius: 2px;
}

.table>tfoot>tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.table>tfoot>tr:last-child>th:first-child {
  border-bottom-left-radius: 2px;
}

.table>tfoot>tr:last-child>th:last-child {
  border-bottom-right-radius: 2px;
}

.table>tbody>tr:last-child>th:first-child,
.table>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 2px;
}

.table>tbody>tr:last-child>th:last-child,
.table>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 2px;
}

.table>tfoot+tbody>tr:last-child>th:first-child,
.table>tfoot+tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 0;
}

.table>tfoot+tbody>tr:last-child>th:last-child,
.table>tfoot+tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 0;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ffffff;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    border: 1px solid #ffffff;
  }

  >thead>tr>th,
  >thead>tr>td {
    border-bottom-width: 2px;
  }
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #fcfcfd;
}

.table-hover>tbody>tr:hover {
  background-color: #e7e9ee;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0.01%;

  @include media('<md') {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ffffff;
  }

  th,
  td {
    @include media('<md') {
      white-space: nowrap;
    }
  }

  >.table-bordered {
    @include media('<md') {
      border: 0;

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* colors */
.table>.success>tr>th,
.table>.success>tr>td {
  background-color: #16648D;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.primary>tr>th,
.table>.primary>tr>td {
  background-color: #16648D;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.success-2>tr>th,
.table>.success-2>tr>td {
  background-color: #16648D;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.danger>tr>th,
.table>.danger>tr>td {
  background-color: #d9534f;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.success>tr:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.table>.success>tr:first-child>th:first-child,
.table>.success>tr:first-child>td:first-child {
  border-top-left-radius: 0;
}

.table>.success>tr:first-child>th:last-child,
.table>.success>tr:first-child>td:last-child {
  border-top-right-radius: 0;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;

  a {
    display: inline-flex;
    width: 20px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    vertical-align: baseline;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //color: #4f66a0;
  color: #000000;
}

.ant-tabs-tab:hover {
  color: #16648D;
}

.invoice_storno_color {
  background-color: #FF7373 !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #4f66a0;
}

.ant-tabs-ink-bar {
  background: #4f66a0;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #16648D;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4f66a0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar {
  background: #4f66a0;
}

td.ant-table-column-sort {
  background: initial;
}

.actions-block {
  background-color: $wht;
  color: #008139;
  min-height: 80px;
  border-radius: $table-border-radius-top;
  //padding-top: 10px ;
  display: flex;
  align-items: center;
  gap: 10px;
}

.table {
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;
  overflow: hidden !important;
  // .actions-block {
  //   margin-bottom: 8px;
  // }
}

.ant-table-filter-dropdown {
  padding: 10px;
  border-radius: 4px;

  // .ant-btn {
  //   background: linear-gradient(180deg, #3372ed 0%, #1138c2 100%);
  //   border-radius: 5px;
  //   font-weight: 600;

  //   width: $small-btn-width !important;
  //   span {
  //     color: #fff;
  //   }
  // }
}

//th.ant-table-cell{
//  padding: 20px 10px !important;
//}

.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
  opacity: 0.8 !important;
  color: #000 !important;
}

.lock .disabled_edit {
  cursor: not-allowed;
  opacity: .3;
}

.lock:hover .icon-lock {
  display: inline;
}

.lock:hover svg {
  // color: #1138c2 !important;
}