.profile-panel {
    min-height: calc( 100% - 15px );
    display: flex;
}

.profile-wrapper {
    min-height: 100%;
    width: 100%;

    .profile-left {
        background-color: $c-secondary;
        border-radius: 4px 4px 0 0;
        overflow: hidden;

        @include media(">=md") {
            border-radius: 4px 0 0 4px;
        }
    }

    .profile-right {
        .panel-body {
            border-radius: 0 0 4px 4px;
            overflow: hidden;

            @include media(">=md") {
                border-radius: 0 0 4px 4px;
            }
        }
    }

    .profile-right-body {
        padding: 0 10px;
    }
}

.profile-header {
    background-color: darken(saturate(adjust-hue($c-secondary, -1), 0.32), 5.10);
    padding: 1.25em;
    border-radius: 4px 4px 0 0;
    text-align: center;
    color: #fff;

    @include media(">=md") {
        border-radius: 4px 0 0 0;
    }

    .list-group {
        margin: 1.25em 0;
        border-top: 1px solid rgba(255,255,255,.2);
        text-transform: uppercase;
        color: rgba(255,255,255,.7);

        li {
            border-bottom: 1px solid rgba(255,255,255,.2);
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: .625em 0;
            
            span {
                font-size: 1.125em;
                font-weight: bold;
                letter-spacing: .05em;
                display: inline-block;

                &:first-child {
                    margin-right: 1em;
                }

                &:last-child {
                    color: #4F66A0;
                }
            }
        }
    }

    button {
        + button {
            margin-top: .375em;
        }
    }
}

.profile-body {
    padding: 1.25em;

    dl{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    dt {
        padding-top: 16px;
        margin-bottom: 10px;
        color: #fff;
        text-transform: uppercase;
        font-size: 13px;

        &:first-child {
            padding-top: 0;
        }
    }

    dd {
        border-bottom: 1px solid rgba(255,255,255,.2);
        font-size: 1.125em;
        padding-bottom: 16px;
        color:#9fa8bc;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        i {
            vertical-align: middle;
            margin-right: 6px;
            position: relative;
            top: -1px;
        }

        span {
            vertical-align: bottom;
        }

        button {
            margin-right: 6px;
            color: rgba(255,255,255,.7);
            transition: color .2s;

            &:hover, &:active {
                color: #fff;
            }

            i {
                margin:0;
                font-size: 1.5em;
                top:auto;
            }
        }
    }
}

.profile-avatar {
    margin:0 auto 10px;
    overflow: hidden;
    border-radius: 9999px;
    border: 2px solid lighten(saturate(adjust-hue($c-secondary, -1), 0.37), 4.90);
    width: 114px;
    height: 114px;
    position: relative;

    button {
        overflow: hidden;
        border-radius: 9999px;
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 1px;
        right: 4px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.profile-avatar-editable{
    button{
        &:hover{
            opacity:.5;
        }
    }
}

.profile-avatar-non-editable{
    button{
        &:hover{
            cursor: default;
        }
    }
}

.profile-name {
    font-weight: 300;
    font-size: 1.25em;
    margin-bottom: 6px;
    letter-spacing: .025em;
}

.profile-designation {
    font-size: .875em;
    font-weight: 300;
    margin-bottom: 20px;
    letter-spacing: .025em;
    opacity: .8;
}

//   .profile-wrapper .profile-right .profile-right-body .panel {
//     border-bottom: 1px solid #dbdfe6;
//     padding-bottom: 40px;
//     margin-bottom: 35px;
//   }
//   .profile-wrapper .profile-right .profile-right-body .panel-heading {
//     padding: 0 0 15px;
//   }
//   .profile-wrapper .profile-right .profile-right-body .panel-body {
//     padding: 0 0 15px;
//     line-height: 18px;
//   }
//   .profile-wrapper .profile-right .profile-right-body .panel-footer {
//     padding: 0;
//     background-color: transparent;
//   }
//   .profile-wrapper .profile-right .profile-right-body .form-group {
//     margin: 15px 0 0;
//   }
//   .profile-wrapper .profile-right .profile-right-body .form-control {
//     border-color: #bdc3d1;
//     padding-left: 12px;
//     border-top-right-radius: 2px;
//     border-top-left-radius: 2px;
//   }
//   .profile-wrapper .profile-sidebar {
//     padding-left: 20px;
//   }
//   @media (min-width: 992px) and (max-width: 1024px) {
//     .profile-wrapper .profile-sidebar {
//       width: 100%;
//       display: block;
//       float: left;
//       margin-top: 20px;
//       padding-left: 0;
//     }
//     .profile-wrapper .profile-sidebar .row > div {
//       width: 50%;
//     }
//   }
//   @media (max-width: 991px) {
//     .profile-wrapper .profile-sidebar {
//       padding-left: 0;
//       margin-top: 20px;
//     }
//   }