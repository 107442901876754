.single-company {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;

  &__left-part {
    width: 60vw;
    // background-color: paleturquoise;
    margin-left: 15px;

    .task-kartica {
      max-width: 800px;
      margin-top: 20px;
      // border: 1px solid #000;
      border-radius: 10px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 16px rgba(17, 17, 26, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .task-obligacija {
        width: 100%;

        // display: flex;
        // justify-content: space-between;
        // align-items: center;
      }
    }

    .taskovi-no-task {
      text-align: center;
      padding: 200px 0px;
      background-color: #fff;
      margin-top: 18px;
      border-radius: 10px;
      box-shadow: 0px 0px 16px rgba(17, 17, 26, 0.1);
    }
  }

  &__right-part {
    width: 500px;
  }
}
