// .ant-menu-item-selected{
//   background-color: rgb(37,157,171) !important;
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //background-color: #445786;
  //background: linear-gradient(180deg, #3372ED 0%, #1138C2 100%);
  background: #3372ED;
}

.submenu{
  text-align: center;
  color: $wht;
  font-size: 18px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-submenu-title:hover {
  // background: rgba(0, 129, 57, 0.1);
  background: rgba(22, 99, 141, 0.3)
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: white;
}
.ant-menu-dark .ant-menu-item:hover {
  background: rgba(108, 156, 180, 0.3);
}
.ant-menu-dark .ant-menu-item>span>a {
  color: black;
}
.ant-menu-dark .ant-menu-item>span>a:hover {
  color: black;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}

.ant-menu-dark .ant-menu-item{
  color:black;
}
