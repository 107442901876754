.ant-pagination {
  &-item-active {
    border-color: #16648D;
    color: #fff !important;
    background-color: #16648D;
    button {
      &.ant-pagination-item-link {
        color: #16648D;
      }
    }
    &:hover {
      border-color: darken($c-primary, 5);

      a,
      a:visited {
        color: #fff;
      }
    }

    a,
    a:visited {
      color: #fff;

      &:hover,
      &:active {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  // &-options-size-changer {
  //     &.ant-select {
  //         margin-right: 0;
  //     }
  // }

  li {
    vertical-align: bottom;
  }

  .anticon {
    vertical-align: middle;
  }
}

.ant-table-pagination {
  &.ant-pagination {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.anticon.anticon-left {
  color: #16648D;
  &:hover {
    color: #fff;
  }
}

.anticon.anticon-right {
  color: #16648D;
  &:hover {
    color: #fff;
  }
}

.ant-pagination-item a:hover {
  text-decoration: none;
  color: #fff;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff;
}
