form {
  .ant-input-group-wrapper {
    display: block;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-input-group {
  font-size: 16px;

  >.ant-input {
    &:last-child {
      border-radius: 4px;
    }
  }

  .ant-calendar-picker-input {
    float: none;
  }
}

.ant-input-group-addon {
  padding-left: 0;
  border: none;
  border-radius: 0;

  &:first-child {
    background-color: transparent;
    color: inherit;
    display: block;
    font-size: .875em;
    font-weight: bold;
    width: 100% !important;
    margin-bottom: .5em;

    .form-horizontal & {
      width: 20% !important;
      text-align: right;
      display: table-cell;
      margin: 0;
    }
  }
}

.ant-input-lg {
  font-size: 14px;
}

.ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.ant-select-lg {
  .ant-select-selection__rendered {
    font-size: 14px;
  }
}

.ant-select-tree-switcher {
  .anticon {
    vertical-align: inherit;
  }
}

.ant-select-tree-node-content-wrapper {
  vertical-align: middle;
}

.ant-select-selection__choice__remove {
  .anticon {
    vertical-align: text-top;
  }
}

textarea {
  width: 100%;
  border-radius: 4px;
  resize: vertical;
  height: 140px;
  overflow: auto;
  flex: 1 1 auto;
  border: 1px solid #d9d9d9;
  padding: .75em 11px;
  color: rgba(0, 0, 0, .65);
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Lato', sans-serif;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    border-color: #16648D !important;
    outline: none;
  }
}

.ant-radio-inner:after {
  background-color: #4F66A0 !important;
}

.ant-input-group>.ant-input:not(:first-child):not(:last-child) {
  border-radius: 4px;
}

.ant-card-head-title {
  text-align: left;
}

// .ant-card-head {
//   background-color: #464f63;
//   color: #fff;
//   text-transform: uppercase;
// }